var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('v-simple-table', [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "7%"
    }
  }, [_vm._v("번호")]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("카테고리")]), _c('th', {
    staticStyle: {
      "width": "66%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticStyle: {
      "width": "7%"
    }
  }, [_vm._v("조회")])])]), _c('tbody', _vm._l(_vm.boards, function (board, index) {
    return _c('tr', {
      key: board._id,
      on: {
        "click": function ($event) {
          return _vm.$emit('click', board);
        }
      }
    }, [_c('td', [_vm._v(_vm._s(_vm.summary.totalCount - (_vm.page - 1) * _vm.limit - index))]), _c('td', [_vm._v(_vm._s(board.category))]), _c('td', [_c('div', {
      staticClass: "ellipsis"
    }, [_vm._v(_vm._s(board.subject))])]), _c('td', [_vm._v(_vm._s(board.createdAt.toDateTime()))]), _c('td', [_vm._v(_vm._s(board.viewCount))])]);
  }), 0)]), _vm._t("pagination")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }